import styled from 'react-emotion'
import { rem } from 'polished'
import theme from '../styles/theme'

export const General = styled.div`
  color: ${theme.colors.generalCopy};

  ul,
  ol {
    padding-left: 40px;
  }

  p,
  li {
    font-size: 14px;
    line-height: 24px;
  }

  li {
    position: relative;
  }

  ul {
    list-style: none;

    > li {
      &:before {
        content: '•';
        position: absolute;
        left: -22px;
        transform: translateY(-1px);
        color: ${theme.colors.listBullet};
        font-size: 18px;
        font-weight: 900;
        line-height: 24px;
      }
    }
  }
`

export default General
