import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'react-emotion'
import Helmet from 'react-helmet'
import { rem } from 'polished'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HeroContent from '../components/HeroContent'
import {
  HeroHeadingPrimary,
  HeroHeadingSecondary,
} from '../components/HeroHeadings'
import LayoutContainer from '../components/LayoutContainer'
import General from '../components/General'
import SectionHeading from '../components/SectionHeading'
import Section, { sectionStyles } from '../components/Section'

const GeneralHeading = HeroHeadingPrimary.withComponent('h1')

const GeneralSection = styled(General)`
  ${sectionStyles};
`

export default ({ data, pageContext }) => {
  const article = data.article.data
  const { site } = data

  return (
    <Layout>
      <Helmet>
        <title>
          {article.title} - {site.siteMetadata.title}
        </title>
      </Helmet>
      <Hero
        {...pageContext.heroProps}
        src={article.heroImage.url}
        alt={article.title}>
        <HeroContent>
          <GeneralHeading>{article.title}</GeneralHeading>
        </HeroContent>
      </Hero>
      <LayoutContainer>
        <GeneralSection>
          <SectionHeading
            dangerouslySetInnerHTML={{ __html: article.introduction.html }}
          />
          <div
            className={css`
              max-width: 585px;
            `}
            dangerouslySetInnerHTML={{ __html: article.body.html }}
          />
        </GeneralSection>
      </LayoutContainer>
    </Layout>
  )
}

export const query = graphql`
  query($prismicId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    article: prismicArticle(prismicId: { eq: $prismicId }) {
      data {
        title
        heroImage: hero_image {
          url
          dimensions {
            width
            height
          }
        }
        introduction {
          html
        }
        body {
          html
        }
      }
    }
  }
`
